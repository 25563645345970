<template>
  <div>
    <!-- Product detail information -->
    <v-row>
      <v-col cols="12" md="6">
        <v-card class="ribbon-container">
            <div class="ribbon" style="font-size: 20px">PURCHASED</div>
            <v-img :aspect-ratio="16/9" :src="product.featurePicture"></v-img>
          <v-card-title>
            <h1 class="product__title">{{product.title}}</h1>
          </v-card-title>
          <v-card-text>
            <h3 class="product__content">{{product.content}}</h3>
            <h3 class="product__content">{{product.completionTime}}</h3>
            <h2 class="product__price">R {{product.price}}</h2>
            <p class="product__vat">One-time, VAT included</p>
          </v-card-text>
            <a href="/user/purchases" class="product__btn">Purchases</a>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
  export default {
    data() {
      return {
          product: this.$route.query.View,
      }
    },
  }
</script>

<style lang="scss" scoped>
@import "@/assets/css/scss/_variables.scss";
.product {
    &__title {
        font-family: $lato;
        line-height: 2;
    }
    &__content {
        font-family: $lato;
        line-height: 2;
    }
    &__price {
        font-family: $lato;
        line-height: 2;
    }
    &__vat {
        line-height: 2;
    }
    &__btn {
    margin: 30px auto;
    display: block;
    width: 150px;
    padding: 16px 25px;
    border: 1px solid $primary;
    background-color: $btn-background;
    font-family: $lato;
    color: $primary;
    font-size: 20px;
    line-height: 20px;
    text-decoration: none;
    cursor: pointer;
    border-radius: 0;

    &:hover {
      background-color: $primary;
      color: $btn-hover;
    }
  }
}

.ribbon {
    color: $white;
    background-color: $primary-light;
    border-top: solid 2px $primary;
    border-bottom: solid 2px $primary;
    text-align: center;
    padding: .5rem;
    transform-origin: center center;
    transform: translate(-50%, 50%) rotate(-45deg);
    box-shadow: 0 1px 2px 0 rgba(0,0,0,0.2);
    line-height: 1.3rem;
    margin: 1rem 0 0 5rem;
    position: absolute;
    top: 0;
    z-index: 9;
    left: -22px;
    width: 275px;
}

.ribbon-container {
  position: relative;
  overflow: hidden;
}
</style>